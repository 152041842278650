import React, {
  useEffect, useState, useCallback, useMemo
} from 'react';
import sha256 from 'sha256';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MuiButton from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import TablePagination from '@mui/material/TablePagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';

import SearchAppBar from '../../components/SearchAppBar';
import {
  Loading, Button, SingleModal
} from '../../components';
import * as Helpers from '../../helpers';

import './History.css';

function History(props) {
  const {
    getHistory,
    admin: {
      loading, permissions, rates,
    },
    match: { params: { table, id } },
    reject,
    refresh,
    issueLoan,
    updateRepayment,
    history: navHistory,
    repay,
    completeWithdrawal,
    blacklist,
    getRates,
    chargeUser,
    approveWithdrawal,
    getUserBreakdown,
  } = props;

  const [showMore, setShowMore] = useState(false);
  const [actionID, setActionID] = useState(0);
  const [loanID, setLoanID] = useState(0);
  const [loanIdDisplay, setLoanIdDisplay] = useState(`loan_id: ${0}`);
  const [allLoansbreakdown, setAllLoansbreakdown] = useState([]);
  const [page, setPage] = useState({
    loan: 0,
    portfolio: 0,
    repayment: 0,
    user: 0,
    withdrawal: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState({
    loan: 10,
    portfolio: 10,
    repayment: 10,
    user: 10,
    withdrawal: 10,
  });
  const [history, setHistory] = useState({
    loan: [],
    portfolio: [],
    repayment: [],
    user: [],
    withdrawal: [],
  });
  const [total, setTotal] = useState({
    loan: 0,
    portfolio: 0,
    repayment: 0,
    user: 0,
    withdrawal: 0,
  });
  const [blacklistActions, setBlacklistActions] = useState({
    loan: false,
    repayment: false,
    signin: false,
    withdrawal: false,
  });

  const loadingHistory = useMemo(() => loading.some(url => url === `/admin/history/${table}/${id}?type=${'loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'}${(rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] !== null && (page['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] * rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']) !== null) ? `&limit=${rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']}&offset=${page['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal'] * rowsPerPage['loan' || 'portfolio' || 'repayment' || 'user' || 'withdrawal']}` : ''}`), [id, loading, page, rowsPerPage, table]);

  const getUserBreakdownData = useCallback(user_id => {
    if (user_id) {
      getUserBreakdown(
        user_id,
        res => {
          Helpers.notification.success(res.message);
          // eslint-disable-next-line no-console
          // console.log('user-breakdown ', res.data.breakdown);
          setAllLoansbreakdown(res.data.breakdown);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    }
  }, [getUserBreakdown]);

  const getHistoryData = useCallback(() => {
    ['loan', 'portfolio', 'repayment', 'user', 'withdrawal'].forEach(tab => {
      getHistory(
        table,
        id,
        tab,
        rowsPerPage[tab],
        page[tab] * rowsPerPage[tab],
        res => {
          setHistory(prev => ({ ...prev, [tab]: res.data[tab].all }));
          setTotal(prev => ({ ...prev, [tab]: res.data[tab].total }));
          if (tab === 'user') {
            getUserBreakdownData(res.data.user.all[0].user_id);
            const actions = res.data.user.all[0]?.blacklist?.actions;
            if (actions) {
              setBlacklistActions(actions);
            }
          }
          // eslint-disable-next-line no-console
          console.log(`${tab}-history:`, res.data[tab].all);
        }
      );
    });
  }, [getHistory, getUserBreakdownData, id, page, rowsPerPage, table]);

  useEffect(() => {
    getHistoryData();
    getRates();
  }, [getRates, getHistoryData]);

  history.portfolio.map((item, i) => {
    const hash = sha256(item.loan_id || '').substring(0, 8);
    history.portfolio[i] = { 'portfolio hash id': hash, ...item, refresh: true };
    return null;
  });

  history.loan.map((item, i) => {
    let tenureDataObject = {};
    if (item.disbursed && Number(item.tenure) > 0) {
      const disbursedDate = new Date(item.disbursed);
      disbursedDate.setMonth(disbursedDate.getMonth() + 1);
      const startMonthText = disbursedDate.toLocaleString('en-US', { month: 'long' });
      const startYear = disbursedDate.getFullYear();

      disbursedDate.setMonth(disbursedDate.getMonth() + (Number(item.tenure) - 1));
      const endMonthText = disbursedDate.toLocaleString('en-US', { month: 'long' });
      const endYear = disbursedDate.getFullYear();

      tenureDataObject = {
        end_date: `${endMonthText}, ${endYear}`,
        monthly_principal: (item.principal / item.tenure).toFixed(2),
        start_date: `${startMonthText}, ${startYear}`,
      };
    }
    const hash = sha256(item.loan_id || '').substr(0, 8);
    history.loan[i] = {
      'loan hash id': hash,
      ...item,
      tenure_data: tenureDataObject,
      ...(Helpers.permission(['update-loan-status-issue'], permissions) ? { issue: true } : {}),
      ...(Helpers.permission(['update-loan-status-reject'], permissions) ? { reject: true } : {}),
    };
    return null;
  });

  history.withdrawal.map((item, i) => {
    const hash = sha256(item.withdrawal_id || '').substr(0, 8);
    history.withdrawal[i] = { 'withdrawal hash id': hash, ...item, action: true };
    return null;
  });

  history.repayment.map((item, i) => {
    const hash = sha256(item.repayment_id || '').substr(0, 8);
    history.repayment[i] = { 'repayment hash id': hash, ...item, update: true };
    return null;
  });

  const toggle = useCallback(() => {
    setShowMore(!showMore);
  }, [setShowMore, showMore]);

  const formatDate = formattableDate => {
    let day = '';
    let month = '';
    let year = '';
    let hours = '';
    let seconds = '';
    let minutes = '';
    let date = new Date(formattableDate);
    if (date.getTime() <= new Date().getTime()) {
      date = moment(date, 'MM/DD/YYYY').add(1, 'M').toDate();
    }
    day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    year = date.getFullYear();
    hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const spreadArray = compactArr => {
    const spreadArr = JSON.parse(JSON.stringify(compactArr));
    return spreadArr.map(obj => {
      const newObj = { ...obj };
      const keys = Object.keys(obj);
      keys.forEach(key => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
          const innerKeys = Object.keys(obj[key]);
          innerKeys.forEach(innerKey => {
            if (typeof obj[key][innerKey] === 'object' && obj[key][innerKey] !== null) {
              const innerInnerKeys = Object.keys(obj[key][innerKey]);
              innerInnerKeys.forEach(innerInnerKey => {
                const val = `${innerKey}_${innerInnerKey}`;
                newObj[val] = obj[key][innerKey][innerInnerKey];
              });
              delete newObj[innerKey];
            }
            newObj[`${key}_${innerKey}`] = obj[key][innerKey];
          });
          delete newObj[key];
        }
      });
      return { ...newObj };
    });
  };

  // ------------------- start repay --------------------- //
  // currency input
  const currency = useMemo(() => ({ Cents: 'USD', Kobo: 'NGN' }), []);
  const [repayCurrencyDisplay, setrepayCurrencyDisplay] = useState('Cents');
  const [currencyAnchor, setCurrencyAnchor] = useState(null);
  const currencyOpen = Boolean(currencyAnchor);

  const handleCurrencyInput = useCallback(event => {
    setCurrencyAnchor(event.currentTarget);
  }, []);
  const handleCurrencyClose = useCallback(() => {
    setCurrencyAnchor(null);
  }, []);
  const handleCurrencyValue = useCallback(e => {
    setrepayCurrencyDisplay(e.target.textContent);
  }, []);

  // amount to repay
  const [repayAmount, setRepayAmount] = useState({
    value: 0,
  });
  const handleAmountInput = useCallback(event => {
    setRepayAmount({
      value: event.target.value,
    });
  }, []);

  // repay modal
  const loadingRepay = useMemo(() => loading.some(url => url === '/admin/repay'), [loading]);
  const [repayStep, setRepayStep] = useState(1);
  const handleSetRepayStep = useCallback(step => {
    setRepayStep(step || 2);
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const handleModalOpen = useCallback(() => setModalOpen(true), []);
  const handleModalClose = useCallback(() => {
    setLoanID(0);
    setLoanIdDisplay(`loan_id: ${0}`);
    setRepayAmount({
      value: 0,
    });
    handleSetRepayStep(1);
    setModalOpen(false);
  }, [handleSetRepayStep]);

  // loanId to repay
  const handleLoanIdInput = useCallback(event => {
    if (Helpers.permission(['create-repayment-loan.id'], permissions)) {
      setLoanID(event.target.value.split(' ')[1]);
      setLoanIdDisplay(`loan_id: ${event.target.value.split(' ')[1] || ''}`);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-loan.id]');
    }
  }, [permissions]);

  // submit repay
  const handleAmountSubmit = useCallback(event => {
    event.preventDefault();
    let { value } = repayAmount;
    const repayCurrency = currency[repayCurrencyDisplay];
    value = repayCurrency === 'NGN' ? value / rates.buy : value;
    const excess = value > history.user[0]?.info.total.currentDebt;
    if (Helpers.permission(['create-repayment'], permissions) && !excess) {
      if (value > 0) {
        handleSetRepayStep(2);
      }
    } else {
      const error = Helpers.permission(['create-repayment'], permissions)
        ? (
          excess ? 'You cannot repay more than Total Debt.' : ''
        )
        : 'You do not have the necessary permission(s): [create-repayment]';
      Helpers.notification.error(error);
    }
  }, [currency, handleSetRepayStep, history.user, permissions, rates.buy, repayAmount, repayCurrencyDisplay]);

  const handleRepay = useCallback(() => {
    const amount = parseInt(repayAmount.value, 10);
    const { user_id } = history.user[0];
    const repayCurrency = currency[repayCurrencyDisplay];
    repay(
      amount,
      user_id,
      repayCurrency,
      loanID || 0,
      successRes => {
        Helpers.notification.success(successRes.message);
        getHistoryData();
      },
      errorRes => {
        Helpers.notification.error(errorRes.message);
      }
    );
    setLoanID(0);
    setLoanIdDisplay(`loan_id: ${0}`);
    setRepayAmount({
      value: 0,
    });
    handleSetRepayStep(1);
    handleModalClose();
  }, [currency, getHistoryData, handleModalClose, handleSetRepayStep, history.user, loanID, repay, repayAmount.value, repayCurrencyDisplay]);
  // ------------------- stop repay --------------------- //

  // ------------------- start refresh --------------------- //
  const loadingRefresh = useMemo(() => loading.some(url => url === '/admin/portfolio'), [loading]);
  const refreshPortfolio = useCallback((_id, _user) => () => {
    setActionID(_id);
    refresh(_user, () => getHistoryData());
  }, [getHistoryData, refresh]);
  // ------------------- stop refresh --------------------- //

  // ------------------- start reject --------------------- //
  const loadingReject = useMemo(() => loading.some(url => url === '/admin/reject'), [loading]);

  const rejectLoan = useCallback(_id => () => {
    setActionID(_id);

    if (Helpers.permission(['update-loan-status-reject'], permissions)) {
      reject(
        _id,
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
        },
        err => {
          Helpers.notification.success(err.message);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-reject]');
    }
  }, [permissions, getHistoryData, reject]);
  // ------------------- stop reject --------------------- //

  // ------------------- start issue --------------------- //
  const loadingIssue = useMemo(() => loading.some(url => url === '/admin/issueLoan'), [loading]);

  const loanIssue = useCallback(_id => () => {
    setActionID(_id);
    if (Helpers.permission(['update-loan-status-issue'], permissions)) {
      issueLoan(
        _id,
        res => {
          getHistoryData();
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err);
        }
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-loan-status-issue]');
    }
  }, [permissions, getHistoryData, issueLoan]);
  // ------------------- stop issue --------------------- //

  // ------------------- start repayment update --------------------- //
  const loadingUpdate = useMemo(() => loading.some(url => url === '/admin/repayment/update'), [loading]);

  const [repaymentValues, setRepaymentValues] = useState({});
  const handleSetRepaymentValues = useCallback((e, key, repayment_id) => {
    setRepaymentValues({
      ...repaymentValues,
      [key]: e.target.value,
      repayment_id,
    });
  }, [repaymentValues]);

  const [repaymentModalOpen, setRepaymentModalOpen] = useState(false);
  const handleRepaymentModalOpen = useCallback(() => setRepaymentModalOpen(true), []);
  const handleRepaymentModalClose = useCallback(() => {
    setRepaymentValues({});
    setRepaymentModalOpen(false);
  }, []);

  const handleRepaymentUpdateSubmit = useCallback((event, repayment_id) => {
    event.preventDefault();
    setActionID(repayment_id);
    // check if repayment time was over one hr ago
    // const currentTime = new Date();
    // const repaymentDate = new Date(date);
    // const elapsedHours = Math.ceil(Math.abs(currentTime - repaymentDate) / (60 * 60 * 1000));
    // if (elapsedHours <= 3) {
    if (Object.keys(repaymentValues).length !== 0) {
      if (Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions)) {
        handleRepaymentModalOpen();
      } else {
        Helpers.notification.error('You do not have the necessary permission(s): [update-repayment-principal.interest, update-repayment-date]');
      }
    } else {
      Helpers.notification.error('No updates were made');
    }
    // } else {
    // Helpers.notification.error('Not Allowed! Repayment was made over 3 hours ago');
    // }
  }, [permissions, handleRepaymentModalOpen, repaymentValues]);

  const repaymentUpdate = useCallback(() => {
    const {
      date,
      interest,
      principal,
      repayment_id,
    } = repaymentValues;
    if (actionID === repayment_id) {
      updateRepayment(
        actionID,
        principal,
        interest,
        date,
        res => {
          getHistoryData();
          Helpers.notification.success(res.message);
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error('Incorrect update attempt');
    }
    handleRepaymentModalClose();
  }, [actionID, getHistoryData, handleRepaymentModalClose, repaymentValues, updateRepayment]);
  // ------------------- stop repayment update --------------------- //

  // ------------------- start complete withdrawal --------------------- //
  const loadingCompleted = useMemo(() => loading.some(url => url === '/admin/withdrawal/complete'), [loading]);
  const [withdrawalModalOpen, setWithdrawalModalOpen] = useState(false);
  const handleWithdrawalModalOpen = useCallback(() => setWithdrawalModalOpen(true), []);
  const handleWithdrawalModalClose = useCallback(() => {
    setWithdrawalModalOpen(false);
  }, []);
  const handleWithdrawalCompleteSubmit = useCallback((event, withdrawal_id) => {
    event.preventDefault();
    setActionID(withdrawal_id);
    if (Helpers.permission(['update-withdrawal-status-complete'], permissions)) {
      handleWithdrawalModalOpen();
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-withdrawal-status-complete]');
    }
  }, [handleWithdrawalModalOpen, permissions]);

  const withdrawalComplete = useCallback(() => {
    completeWithdrawal(
      actionID,
      res => {
        getHistoryData();
        Helpers.notification.success(res.message);
      },
      err => {
        Helpers.notification.error(err.message);
      }
    );
    handleWithdrawalModalClose();
  }, [actionID, completeWithdrawal, getHistoryData, handleWithdrawalModalClose]);
  // ------------------- stop complete withdrawal --------------------- //

  // ------------------- start blacklist --------------------- //
  const [blacklistReason, setBlacklistReason] = useState('');
  const [blacklistModalOpen, setBlacklistModalOpen] = useState(false);
  const loadingBlacklist = useMemo(() => loading.some(url => url === '/admin/blacklist'), [loading]);

  const handleBlacklistModalOpen = useCallback(() => {
    setBlacklistReason(history.user?.[0]?.blacklist?.reason || '');
    setBlacklistModalOpen(true);
  }, [history.user]);

  const handleBlacklistModalClose = useCallback(() => {
    setBlacklistModalOpen(false);
  }, []);

  const handleSetBlacklistReason = useCallback(event => {
    setBlacklistReason(event.target.value);
  }, []);

  const handleBlacklist = useCallback(() => {
    // add permissions check
    if (Helpers.permission(['update-user-blacklist'], permissions)) {
      blacklist(
        id,
        blacklistReason,
        JSON.stringify(blacklistActions),
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
          setBlacklistReason('None');
        },
        () => {}
      );
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-user-blacklist]');
    }
    handleBlacklistModalClose();
  }, [permissions, handleBlacklistModalClose, blacklist, id, blacklistReason, blacklistActions, getHistoryData]);

  const handleBlacklistActionToggle = useCallback(e => {
    setBlacklistActions({
      ...blacklistActions,
      [e.target.name]: e.target.checked,
    });
  }, [blacklistActions]);
  // ------------------- stop blacklist --------------------- //

  // ------------------- start charge --------------------- //
  const [chargeAmount, setChargeAmount] = useState(0);
  const [chargeStep, setChargeStep] = useState(1);
  const [chargeModalOpen, setChargeModalOpen] = useState(false);
  const loadingCharge = useMemo(() => loading.some(url => url === '/admin/charge'), [loading]);

  const handleChargeModalOpen = useCallback(() => {
    setChargeAmount(0);
    if (Helpers.permission(['create-repayment-card'], permissions)) {
      setChargeModalOpen(true);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-card]');
    }
  }, [permissions]);

  const handleSetChargeStep = useCallback(step => {
    setChargeStep(step || 2);
  }, []);

  const handleChargeModalClose = useCallback(() => {
    handleSetChargeStep(1);
    setChargeModalOpen(false);
  }, [handleSetChargeStep]);

  const handleSetChargeAmount = useCallback(event => {
    setChargeAmount(event.target.value);
  }, []);

  const handleCharge = useCallback(() => {
    // add permissions check
    if (Helpers.permission(['create-repayment-card'], permissions)) {
      const { user_id } = history.user[0];
      const total_debt = history.user[0]?.info?.minimum?.currentMinimum?.amount
        || history.user[0]?.info?.total.currentDebt
        || 0;
      if (chargeAmount <= parseInt(total_debt, 10)) {
        chargeUser(
          user_id,
          chargeAmount,
          res => {
            Helpers.notification.success(res.message);
            const val = JSON.parse(JSON.stringify(res.data.details[0]))[Number(user_id)];
            if (val.amount) {
              const { amount } = val;
              Helpers.notification.success(`User was charged ${amount} cents ($${amount / 100}).`);
            }
            getHistoryData();
          },
          err => {
            Helpers.notification.error(err.message);
          }
        );
      } else {
        Helpers.notification.error('You cannot charge a user more than total debt.');
      }
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [create-repayment-card]');
    }
    handleSetChargeStep(1);
    handleChargeModalClose();
  }, [permissions, chargeAmount, chargeUser, getHistoryData, handleChargeModalClose, handleSetChargeStep, history.user]);
  // ------------------- stop charge --------------------- //

  // ------------------- start approve --------------------- //
  const [approveOrReject, setApproveOrReject] = useState('reject');
  const [approveStep, setApproveStep] = useState(1);
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const loadingApprove = useMemo(() => loading.some(url => url === '/admin/withdrawal/approve'), [loading]);

  const handleApproveModalOpen = useCallback((event, withdrawal_id) => {
    event.preventDefault();
    setActionID(withdrawal_id);
    if (Helpers.permission(['update-withdrawal-approve'], permissions) || Helpers.permission(['update-withdrawal-reject'], permissions)) {
      setApproveModalOpen(true);
    } else {
      Helpers.notification.error('You do not have the necessary permission(s): [update-withdrawal-approve, update-withdrawal-reject]');
    }
  }, [permissions]);

  const handleSetApprovalStep = useCallback(step => {
    setApproveStep(step || 2);
  }, []);

  const handleApproveModalClose = useCallback(() => {
    setApproveOrReject('reject');
    handleSetApprovalStep(1);
    setApproveModalOpen(false);
  }, [handleSetApprovalStep]);

  const handleSetApproveOrReject = useCallback(decision => () => {
    setApproveOrReject(decision);
    handleSetApprovalStep(2);
  }, [handleSetApprovalStep]);

  const handleApprove = useCallback(() => {
    // add permissions check
    if (
      (approveOrReject === 'approve' && Helpers.permission(['update-withdrawal-approve'], permissions))
        || (approveOrReject !== 'approve' && Helpers.permission(['update-withdrawal-reject'], permissions))
    ) {
      approveWithdrawal(
        actionID,
        approveOrReject === 'approve',
        res => {
          Helpers.notification.success(res.message);
          getHistoryData();
        },
        err => {
          Helpers.notification.error(err.message);
        }
      );
    } else {
      Helpers.notification.error(`You do not have the necessary permission(s): ${approveOrReject === 'approve' ? '[update-withdrawal-approve]' : '[update-withdrawal-reject]'}`);
    }
    handleSetApprovalStep(1);
    handleApproveModalClose();
  }, [actionID, approveOrReject, approveWithdrawal, permissions, getHistoryData, handleApproveModalClose, handleSetApprovalStep]);
  // ------------------- stop approve --------------------- //

  // -------------------start pagination------------------------ //
  const handleChangePage = useCallback(tableName => (_, newPage) => {
    setPage({ ...page, [tableName]: newPage });
    // getHistoryData();
  }, [page]);

  const handleChangeRowsPerPage = useCallback(tableName => e => {
    setRowsPerPage({ ...rowsPerPage, [tableName]: +e.target.value });
    setPage({ ...page, tableName: 0 });
    // getHistoryData();
  }, [page, rowsPerPage]);
  // ------------------- stop pagination ------------------------ //

  // eslint-disable-next-line no-console
  // console.log('history', history);

  return (
    <>
      <SearchAppBar back={navHistory.goBack} buttonName="back" />

      {loadingHistory ? <Loading size="big" /> : (
        <div>
          {/* User Card */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <Card sx={{ m: 3, minWidth: 275 }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  User Details
                </Typography>
                <Typography>
                  First Name:
                  {' '}
                  <span>{history.user[0]?.firstname}</span>
                  <br />
                  Last Name:
                  {' '}
                  <span>{history.user[0]?.lastname}</span>
                  <br />
                  Email:
                  {' '}
                  <span>{Helpers.permission(['read-user-full'], permissions) ? history.user[0]?.email : 'NP'}</span>
                  <br />
                  Mobile:
                  {' '}
                  <span>
                    {Helpers.permission(['read-user-full'], permissions) ? `+ ${history.user[0]?.mobile}` : 'NP'}
                  </span>
                  <br />
                  Card:
                  {' '}
                  <span>{history.user[0]?.card ? 'YES' : 'NO'}</span>
                  <br />
                  Token:
                  {' '}
                  <span>{history.user[0]?.token ? 'YES' : 'NO'}</span>
                  <br />
                  <span style={{ display: showMore ? 'block' : 'none' }}>
                    Balance:
                    {' '}
                    <span>{Helpers.permission(['read-user-full'], permissions) ? history.user[0]?.balance : 'NP'}</span>
                    <br />
                    Date:
                    {' '}
                    <span>{history.user[0]?.date}</span>
                    <br />
                    Current Minimum Debt (Total):
                    {' '}
                    <span>{history.user[0]?.info.minimum.currentMinimum.amount}</span>
                    <br />
                    Current Minimum Debt (Interest):
                    {' '}
                    <span>{history.user[0]?.info.minimum.currentMinimum.interest}</span>
                    <br />
                    Current Minimum Debt (Principal):
                    {' '}
                    <span>{history.user[0]?.info.minimum.currentMinimum.principal}</span>
                    <br />
                    Current Total Debt:
                    {' '}
                    <span>{history.user[0]?.info.total.currentDebt}</span>
                    <br />
                    Future Minimum Debt (Total):
                    {' '}
                    <span>{history.user[0]?.info.minimum.futureMinimum.amount}</span>
                    <br />
                    Future Minimum Debt (Interest):
                    {' '}
                    <span>{history.user[0]?.info.minimum.futureMinimum.interest}</span>
                    <br />
                    Future Minimum Debt (Principal):
                    {' '}
                    <span>{history.user[0]?.info.minimum.futureMinimum.principal}</span>
                    <br />
                    Future Total Debt:
                    {' '}
                    <span>{history.user[0]?.info.total.futureDebt}</span>
                    <br />
                    Next Due Date:
                    {' '}
                    <span>{(history.withdrawal.length === 0 && history.user[0]?.balance <= 0) || history.user[0]?.info.minimum.futureMinimum.date === '' ? '-' : formatDate(history.user[0]?.info.minimum.futureMinimum.date)}</span>
                    <br />
                    Blacklist:
                    {' '}
                    <span>{history.user[0]?.blacklist === null ? 'NO' : 'YES'}</span>
                    <br />
                    Buy Rate:
                    {' '}
                    <span>{rates.buy}</span>
                    <br />
                    Sell rate:
                    {' '}
                    <span>{rates.sell}</span>
                  </span>
                </Typography>
              </CardContent>
              <CardActions>
                <MuiButton size="small" onClick={toggle}>{ showMore ? 'Hide' : 'Show More' }</MuiButton>
              </CardActions>
            </Card>
            {Helpers.permission(['update-user-blacklist'], permissions) && (
              <Box
                sx={{
                  alignSelf: 'end',
                  display: 'block',
                  m: '24px',
                }}
              >
                <Button
                  type="secondary"
                  loading={loadingBlacklist}
                  onClick={handleBlacklistModalOpen}
                >
                  {history.user[0]?.blacklist === null ? 'Blacklist' : 'Whitelist'}
                </Button>
              </Box>
            )}

            {Helpers.permission(['create-repayment-card'], permissions) && (
              <Box
                sx={{
                  alignSelf: 'end',
                  display: 'block',
                  m: '24px',
                }}
              >
                <Button
                  type="success"
                  loading={loadingCharge}
                  onClick={handleChargeModalOpen}
                >
                  Charge
                </Button>
              </Box>
            )}

            {Helpers.permission(['create-repayment'], permissions) && (
              <Box
                sx={{
                  alignSelf: 'end',
                  display: 'block',
                  m: '24px',
                }}
              >
                <Button
                  type="info"
                  loading={loadingRepay}
                  onClick={handleModalOpen}
                >
                  Repay
                </Button>
              </Box>
            )}

            {Helpers.permission(['read-loan-breakdown'], permissions) && (
              <Box
                sx={{
                  alignSelf: 'end',
                  display: 'block',
                  m: '24px',
                }}
              >
                <CSVLink
                  data={allLoansbreakdown}
                  filename={`${history.user[0]?.email}-loan-breakdown.csv`}
                  className="btn btn-primary csvButton"
                >
                  {Helpers.capitalizeFirstLetter('Export Breakdown')}
                </CSVLink>
              </Box>
            )}
          </Box>

          {/* the three tables */}
          {
            ['portfolio', 'loan', 'withdrawal', 'repayment'].map((tableName, m) => (
              <Box key={m}>
                <Paper
                  sx={{
                    marginLeft: '2.5%', overflow: 'hidden', width: '95%',
                  }}
                >
                  <TableContainer sx={{ maxHeight: '85vh' }}>
                    <Box
                      sx={{
                        display: 'flex',
                      }}
                    >
                      <Typography
                        sx={{
                          flex: '1 1 100%',
                          p: 2,
                        }}
                        variant="h6"
                        id="tableTitle"
                        component="div"
                      >
                        {Helpers.capitalizeFirstLetter(tableName)}
                      </Typography>

                      {
                      history[tableName].length !== 0
                        ? (
                          <Box
                            sx={{
                              display: 'block',
                              m: 2,
                            }}
                          >
                            <CSVLink
                              data={spreadArray(history[tableName], tableName)}
                              filename={`${history.user[0]?.email}-${tableName}.csv`}
                              className="btn btn-primary csvButton"
                            >
                              {Helpers.capitalizeFirstLetter('Export as CSV')}
                            </CSVLink>
                          </Box>
                        )
                        : ''
                      }
                    </Box>
                    <Table stickyHeader aria-label="sticky table">
                      <caption>
                        {
                          history[tableName].length === 0
                            ? (
                              <Box
                                sx={{
                                  alignItems: 'center',
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  m: 2,
                                }}
                              >
                                <span>
                                  There are no
                                  {' '}
                                  {tableName}
                                  s for the selected user
                                  {' '}
                                  {id}
                                  .
                                </span>
                                <MuiButton variant="outlined" color="info" size="medium" onClick={() => window.location.reload()}>
                                  Refresh
                                </MuiButton>
                              </Box>
                            ) : `These are ${tableName}s for the user ${id} only.`
                        }
                      </caption>
                      <TableHead>
                        <TableRow>
                          {history[tableName].length === 0 ? null : Object.keys(history[tableName][0]).map((key, ii) => {
                            let heading = key;
                            heading = key === 'info' ? 'debt' : heading;
                            const removeColumn = (tableName !== 'repayment' && key === 'raw') || (tableName !== 'withdrawal' && key === 'details') || key === 'metadata';

                            if (removeColumn) return null;
                            if (tableName === 'repayment' && key === 'raw') heading = 'author';
                            return (
                              <TableCell
                                key={ii}
                                align="center"
                                style={{ minWidth: 150 }}
                              >
                                {Helpers.capitalizeFirstLetter(heading)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {history[tableName].map((item, i) => (
                          <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                            {Object.keys(history[tableName][0]).map((key, j) => {
                              let val = item[key];
                              const exempted = (key === 'card' || key === 'code' || key === 'password' || key === 'token' || key === 'legal');
                              const isUserStatus = tableName === 'user' && key === 'status';
                              const removeColumn = (tableName !== 'repayment' && key === 'raw') || (tableName !== 'withdrawal' && key === 'details') || key === 'metadata';
                              let noShow = null;
                              if (tableName === 'portfolio' && !Helpers.permission(['read-portfolio-full'], permissions)) noShow = key === 'amount';
                              if (tableName === 'withdrawal' && !Helpers.permission(['read-withdrawal-full'], permissions)) noShow = key === 'details';
                              if (tableName === 'repayment' && !Helpers.permission(['read-repayment-full'], permissions)) noShow = key === 'reference';

                              if (removeColumn) return null;
                              if (noShow) {
                                return (
                                  <TableCell key={j} align="center" title="You do not have the necessary permission(s)">
                                    NP
                                  </TableCell>
                                );
                              }
                              if (tableName === 'repayment' && key === 'raw') val = val.author;
                              if (key === 'breakdown') {
                                val = `Future Debt (Date): ${val.future.date ? formatDate(val.future.date) : '-'}\n Future Debt (Total): ${val.future.amount}\n Future Debt (Interest): ${val.future.interest}\n Future Debt (Principal): ${val.future.principal}\n\n\n Current Debt (Date): ${val.current.date ? formatDate(val.current.date) : '-'}\n Current Debt (Total): ${val.current.amount}\n Current Debt (Interest): ${val.current.interest}\n Current Debt (Principal): ${val.current.principal}\n `;
                              }
                              if (key === 'tenure_data') {
                                val = val.start_date ? `Monthly principal: ${val.monthly_principal};\n Start Date: ${val.start_date};\n End date: ${val.end_date}` : null;
                              }
                              return (
                                <TableCell key={j} align="center" title={typeof val === 'object' || typeof val === 'boolean' ? '' : val}>
                                  {
                                  ((tableName === 'loan' && key === 'reject') || (tableName === 'loan' && key === 'issue') || (tableName === 'portfolio' && key === 'refresh'))
                                    ? (
                                      tableName === 'portfolio' || (key === 'reject' && item.status === 'issued') || (key === 'issue' && item.status === 'requested')
                                        ? (
                                          <Button
                                            type={tableName === 'loan' && key === 'reject' ? 'error' : 'info'}
                                            loading={(item.loan_id === actionID && loadingReject) || (item.loan_id === actionID && loadingIssue) || (item.user_id === actionID && loadingRefresh)}
                                            onClick={tableName === 'loan' && key === 'reject' ? rejectLoan(item.loan_id) : tableName === 'loan' && key === 'issue' ? loanIssue(item.loan_id) : (tableName === 'portfolio' && key === 'refresh') ? refreshPortfolio(item.portfolio_id, item.user_id) : () => {}}
                                          >
                                            {tableName === 'loan' && key === 'reject' ? 'Reject' : tableName === 'loan' && key === 'issue' ? 'Issue' : 'Refresh'}
                                          </Button>
                                        )
                                        : 'N/A'
                                    )
                                    // repayment update
                                    : (Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions) && tableName === 'repayment' && (key === 'principal' || key === 'interest' || key === 'date'))
                                      ? (
                                        <TextField
                                          id="outlined-basic"
                                          variant="outlined"
                                          name=""
                                          defaultValue={val}
                                          onChange={e => handleSetRepaymentValues(e, key, item.repayment_id)}
                                        />
                                      )
                                      : (tableName === 'repayment' && key === 'update')
                                        ? Helpers.permission(['update-repayment-principal.interest', 'update-repayment-date'], permissions)
                                          ? (
                                            <Button
                                              type="info"
                                              loading={item.repayment_id === actionID && loadingUpdate}
                                              onClick={e => handleRepaymentUpdateSubmit(e, item.repayment_id)}
                                            >
                                              Update
                                            </Button>
                                          )
                                          : 'N/A'
                                        : (tableName === 'withdrawal' && key === 'action')
                                          ? (Helpers.permission(['update-withdrawal-status-complete'], permissions) && !item.approved && !(item.rejected || item.status === 'rejected'))
                                            ? (
                                              <Button
                                                type="info"
                                                loading={item.withdrawal_id === actionID && loadingApprove}
                                                onClick={e => handleApproveModalOpen(e, item.withdrawal_id)}
                                              >
                                                Approve
                                              </Button>
                                            )
                                            : (Helpers.permission(['update-withdrawal-status-complete'], permissions) && item.status === 'processing' && item.processor_id === '3')
                                              ? (
                                                <Button
                                                  type="info"
                                                  loading={item.withdrawal_id === actionID && loadingCompleted}
                                                  onClick={e => handleWithdrawalCompleteSubmit(e, item.withdrawal_id)}
                                                >
                                                  Complete
                                                </Button>
                                              )
                                              : 'N/A'
                                          : isUserStatus && val
                                            ? 'ACTIVE'
                                            : isUserStatus && !val
                                              ? 'INACTIVE'
                                              : exempted && !!val
                                                ? 'YES'
                                                : exempted && !val
                                                  ? 'NO'
                                                  : typeof val === 'object'
                                                    ? JSON.stringify(val)
                                                    : val
                                  }
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100, 500, 1000, 2000]}
                    component="div"
                    count={Number(total[tableName])}
                    rowsPerPage={rowsPerPage[tableName]}
                    page={history[tableName].length <= 0 ? 0 : page[tableName]}
                    onPageChange={handleChangePage(tableName)}
                    onRowsPerPageChange={handleChangeRowsPerPage(tableName)}
                  />
                </Paper>
                <br />
                <br />
                <br />
              </Box>
            ))
          }
          <Box
            sx={{
              display: Helpers.permission(['create-repayment'], permissions) ? 'block' : 'none',
              textAlign: 'left',
            }}
          >
            {/* repay modal */}
            <div>
              <SingleModal
                modalName="repay"
                modalOpen={modalOpen}
                modalTitle={
                  repayStep === 1
                    ? 'How much do you want to repay for this user?'
                    : `
                      Are you sure you want to repay
                      ${repayAmount.value}
                      ${repayCurrencyDisplay}
                      ${loanID ? ` into loan_id ${loanID},` : ''}
                      for this user?
                  `
                }
                modalContent={
                  repayStep === 1
                    ? Helpers.permission(['create-repayment-loan.id'], permissions)
                      ? (
                        <>
                          <form
                            className="currencyForm"
                          >
                            <ArrowDropDownIcon
                              className="singleUserDropdown"
                              id="basic-button"
                              aria-controls={currencyOpen ? 'basic-menu' : undefined}
                              aria-haspopup="true"
                              aria-expanded={currencyOpen ? 'true' : undefined}
                              onClick={handleCurrencyInput}
                            />

                            <Menu
                              id="basic-menu"
                              anchorEl={currencyAnchor}
                              open={currencyOpen}
                              onClose={handleCurrencyClose}
                              MenuListProps={{
                                'aria-labelledby': 'basic-button',
                              }}
                            >
                              <MenuItem onClick={handleCurrencyValue}>Cents</MenuItem>
                              <MenuItem onClick={handleCurrencyValue}>Kobo</MenuItem>
                            </Menu>

                            <Typography
                              className="singleUserCurrencyDisplay"
                              sx={{
                                fontSize: '20px',
                              }}
                              onClick={handleCurrencyInput}
                            >
                              {repayCurrencyDisplay}
                            </Typography>
                          </form>

                          <input
                            className="loanIdInput"
                            placeholder="Enter loan ID"
                            name="loanIdInput"
                            value={loanIdDisplay}
                            onChange={handleLoanIdInput}
                            autoComplete="off"
                          />
                        </>
                      )
                      : (
                        <form
                          className="currencyForm"
                        >
                          <ArrowDropDownIcon
                            className="singleUserDropdown"
                            id="basic-button"
                            aria-controls={currencyOpen ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={currencyOpen ? 'true' : undefined}
                            onClick={handleCurrencyInput}
                          />

                          <Menu
                            id="basic-menu"
                            anchorEl={currencyAnchor}
                            open={currencyOpen}
                            onClose={handleCurrencyClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
                            <MenuItem onClick={handleCurrencyValue}>Cents</MenuItem>
                            <MenuItem onClick={handleCurrencyValue}>Kobo</MenuItem>
                          </Menu>

                          <Typography
                            className="singleUserCurrencyDisplay"
                            sx={{
                              fontSize: '20px',
                            }}
                            onClick={handleCurrencyInput}
                          >
                            {repayCurrencyDisplay}
                          </Typography>
                        </form>
                      )
                    : ''
                }
                modalInput={repayStep === 1}
                inputPlaceholder={`Enter amount in ${repayCurrencyDisplay}`}
                inputValue={repayAmount.value}
                onInputChange={handleAmountInput}
                actionButtonText={repayStep === 1 ? 'Submit' : 'Yes'}
                actionButtonDisabled={repayAmount.value < 1}
                modalSubmit={repayStep === 1 ? handleAmountSubmit : handleRepay}
                modalClose={handleModalClose}
              />
            </div>

            {/* repayment update modal */}
            <div>
              <SingleModal
                modalName="updateRepayment"
                modalOpen={repaymentModalOpen}
                modalTitle={
                  `
                    Are you sure you want make these repayment updates for this user?
                    \n
                    ${repaymentValues.repayment_id ? `repayment_id: ${repaymentValues.repayment_id}` : ''}
                    \n
                    ${repaymentValues.principal ? `principal: ${repaymentValues.principal}` : ''}
                    \n
                    ${repaymentValues.interest ? `interest: ${repaymentValues.interest}` : ''}
                    \n
                    ${repaymentValues.date ? `date: ${repaymentValues.date}` : ''}
                  `
                }
                modalSubmit={repaymentUpdate}
                modalClose={handleRepaymentModalClose}
              />
            </div>

            {/* withdrawal complete modal */}
            <div>
              <SingleModal
                modalName="completeWithdrawal"
                modalOpen={withdrawalModalOpen}
                modalTitle="Are you sure you want mark this withdrawal as completed?"
                modalSubmit={withdrawalComplete}
                modalClose={handleWithdrawalModalClose}
              />
            </div>

            {/* blacklist modal */}
            <div>
              <SingleModal
                modalName="Blacklist"
                modalOpen={blacklistModalOpen}
                modalTitle="Are you sure you want to update these blacklist actions for this user?"
                modalContent={(
                  <FormGroup aria-label="position" column="true" sx={{ maxWidth: 120, ml: 0 }}>
                    {(Object.keys(blacklistActions) || []).map((item, i) => (
                      <FormControlLabel
                        key={i}
                        labelPlacement="start"
                        label={item}
                        sx={{ fontSize: '12px' }}
                        control={(
                          <Switch
                            name={item}
                            size="small"
                            aria-label="Switch demo"
                            checked={blacklistActions[item]}
                            onChange={handleBlacklistActionToggle}
                          />
                    )}
                      />
                    ))}
                  </FormGroup>

                )}
                modalInput={Object.keys(blacklistActions).length > 0}
                inputPlaceholder="Enter Reason for Blacklist"
                inputValue={blacklistReason}
                onInputChange={handleSetBlacklistReason}
                actionButtonText="Yes"
                closeButtonText="Cancel"
                actionButtonDisabled={blacklistReason === ''}
                modalSubmit={handleBlacklist}
                modalClose={handleBlacklistModalClose}
              />
            </div>

            {/* Charge modal */}
            <div>
              <SingleModal
                modalName="charge"
                modalOpen={chargeModalOpen}
                modalTitle={
                  chargeStep === 1
                    ? 'How much do you want to charge this user?'
                    : `
                      Are you sure you want to charge
                      \n
                      ${chargeAmount}
                      \n
                      cents ($
                      ${chargeAmount / 100}
                      ) from user
                      \n
                      ${id}
                      ?
                    `
                }
                modalContent=""
                modalInput={chargeStep === 1}
                inputPlaceholder="Enter amount to charge"
                inputValue={chargeAmount}
                onInputChange={handleSetChargeAmount}
                actionButtonText={chargeStep === 1 ? 'Submit' : 'Yes'}
                actionButtonDisabled={chargeAmount < 1}
                modalSubmit={chargeStep === 1 ? handleSetChargeStep : handleCharge}
                modalClose={handleChargeModalClose}
              />
            </div>

            {/* withdrawal approve/reject modal */}
            <div>
              <SingleModal
                modalName="approve"
                modalOpen={approveModalOpen}
                modalTitle={
                  approveStep === 1
                    ? 'Do you want to APPROVE or REJECT this withdrawal?'
                    : `Are you sure you want to ${approveOrReject.toUpperCase()} this withdrawal?`
                }
                defaultButtons={approveStep === 1
                  ? (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        mt: 3,
                      }}
                    >
                      <Button
                        onClick={handleSetApproveOrReject('reject')}
                        type="secondary"
                      >
                        Reject
                      </Button>
                      <Button
                        onClick={handleSetApproveOrReject('approve')}
                        type="success"
                      >
                        Approve
                      </Button>
                    </Box>
                  )
                  : false}
                actionButtonText="Yes"
                modalSubmit={handleApprove}
                modalClose={handleApproveModalClose}
              />
            </div>
          </Box>
          <br />
          <br />
          <br />
        </div>
      )}
    </>
  );
}

export default History;
